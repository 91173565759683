import { Injectable } from '@angular/core';
import { HttpClient,
        HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

import { DataResponseModel,
         ListDataResponseModel,
         PageDataResponseModel,
         TransactionModelDataResponse,
         TransactionFilterDto,
         PaymentMethod,
         UpdateBuyNowTransactionModel,
         ValueDataResponseModel,
         BuyNowTransactionModel} from '../models';
import { retryRequest } from '../pipes/retry-request.operator';

@Injectable()
export class AccountService {

    private actionUrl: string = '/Account';
    private headers: HttpHeaders;
    private httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      };

    constructor(private http: HttpClient,
                private authService: AuthenticationService) {

        this.headers = new HttpHeaders();
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
    }

    public RequestCredit(creditAmount: number): Observable<DataResponseModel> {

       return this.http.get<DataResponseModel>( `${this.actionUrl}/RequestCredit/${creditAmount}/`,
                                                this.httpOptions
                                              )
                        .pipe(retryRequest());
    }

    public TransferCredit(recipientUsername: string, creditAmount: number): Observable<DataResponseModel> {

       return this.http.get<DataResponseModel>( `${this.actionUrl}/TransferCredit/${recipientUsername}/${creditAmount}/'`,
                                                this.httpOptions
                                               )
                        .pipe(retryRequest());
    }

    public ListTransactions(transactionType: string,
                            paymentMethod: string,
                            pageNumber: Number,
                            pageSize: Number): Observable<PageDataResponseModel> {

        let url = `${this.actionUrl}/ListTransactions/${pageNumber}/${pageSize}`;

        if (this.authService.HasRole('Administrator') || this.authService.HasRole('Manager')) {
            
          return this.ListAllTransactionsEx([
                                              'AccountRefill',
                                              'AccountCredit',
                                              'AccountDebit',
                                              'VoucherPurchase',
                                              'VoucherPurchaseBuyNow',
                                              'VoucherSell'
                                            ],
                                            [
                                              'Credit',
                                              'Debit',
                                            ],
                                            pageNumber,
                                            pageSize);
        }
        else {
          return this.http.get<PageDataResponseModel>(url,
                                                      this.httpOptions
                                                      )
                          .pipe(retryRequest());
        }
    }

    public ListAllTransactionsEx( transactionCategories: string [],
                                  transactionTypes: string [],
                                  pageNumber: Number,
                                  pageSize: Number): Observable<PageDataResponseModel> {

         
          const filter: TransactionFilterDto = {
            'categories': transactionCategories,
            'types': transactionTypes,
            'pageNumber': pageNumber,
            'pageSize': pageSize
          };

          return this.http.post<DataResponseModel>( `${this.actionUrl}/ListAllTransactionsEx/`,
                                                    filter,
                                                    this.httpOptions
                                                )
                          .pipe(retryRequest());
    }

    public ListBillPaySyncRequestsQuery(pageNumber: Number,
                                        pageSize: Number): Observable<PageDataResponseModel> {
 
      const now = new Date();
      const year = now.getFullYear();
      const firstDayOfYear = new Date(year, 0, 1);

      const url = `${this.actionUrl}/ListBillPaySyncRequestsQuery/${firstDayOfYear.toISOString()}/${pageNumber}/${pageSize}`;

      return this.http.get<PageDataResponseModel>(url,
                                                  this.httpOptions
                                                  )
                      .pipe(retryRequest());
    }

    public ListTransactionsByUser(username: string): Observable<ListDataResponseModel> {

      const url = `${this.actionUrl}/ListTransactionsByUser/${username}/`;

      return this.http.get<ListDataResponseModel>(url,
                                                  this.httpOptions
                                                  )
                      .pipe(retryRequest());
  }

    public ListExternalTransactions(paymentMethod: string, pageNumber: Number, pageSize: Number): Observable<ListDataResponseModel> {

      const now = new Date();
      const year = now.getFullYear();
      const firstDayOfYear = new Date(year, 0, 1);

      const url = `${this.actionUrl}/ListMobilePayments/${firstDayOfYear.toISOString()}/${pageNumber}/${pageSize}`;

      return this.http.get<ListDataResponseModel>(url,
                                                  this.httpOptions
                                                  )
                      .pipe(retryRequest());
  }

  public ListBuyNowTransactions(pageNumber: Number,
                                pageSize: Number): Observable<PageDataResponseModel> {

    let url = `${this.actionUrl}/ListBuyNowTransactions/${pageNumber}/${pageSize}`;

    return this.http.get<PageDataResponseModel>(url,
                                                this.httpOptions
                                              )
                    .pipe(retryRequest());
   
  }

  public ReceivePayment(clientUsername: string, paymentAmount: number, paymentMethod: PaymentMethod): Observable<TransactionModelDataResponse> {

      return this.http.get<TransactionModelDataResponse>(this.actionUrl + '/ReceivePayment/'
                                                                        + clientUsername + '/'
                                                                        + paymentAmount + '/'
                                                                        + paymentMethod + '/',
                                                                        this.httpOptions
                                              )
                      .pipe(retryRequest());
  }

  public GetTransactionDetails(transactionId: string): Observable<ValueDataResponseModel> {

        const url = this.actionUrl + '/GetTransaction/' + transactionId;

        return this.http.get<ValueDataResponseModel>( url,
                                                      this.httpOptions
                                                    )
                        .pipe(retryRequest());
  }

  public GetBuyNowDetails(transactionId: string): Observable<ValueDataResponseModel> {

    const url = this.actionUrl + '/GetBuyNowTransaction/' + transactionId;

    return this.http.get<ValueDataResponseModel>( url,
                                              this.httpOptions
                                            )
                    .pipe(retryRequest());
  }

  public UpdateBuyNowTransaction( buyNowUpdateModel: UpdateBuyNowTransactionModel): Observable<DataResponseModel> {

    return this.http.post<DataResponseModel>( `${this.actionUrl}/UpdateBuyNowTransaction/`,
                                              buyNowUpdateModel,
                                              this.httpOptions)
                    .pipe(retryRequest());
  }
}
